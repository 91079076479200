




















































































































































































































































.headerTop {
	display: flex;
	justify-content: flex-end;
}

.home {
	height: 100%;
}

.el-header {
	background-color: #ffffff;
}

.el-aside {
	background-color: #233646;

	.el-menu {
		border-right: none;
	}
}

.el-main {
	background-color: #f3f3f4;
}

.el-menu--collapse .el-submenu__title span {
	display: none;
}
